body{
    margin: 0;
}
h1{margin: 0; font-family: "BwModelica-Bold";}

#lpheader{
    background-color: #fe3c00;
    height: 550px;
    width: 100%;
}
.lp-logo{
    width: 140px;
}
.para-reg{
    font-family: "BwModelica";
  }
.para-bold{
font-family: "BwModelica-Bold";
}
.banner-heading{
    color: white;
    font-family: "BwModelica-ExtraBold";
    font-size: 75px;
}
.banner-para{
    font-family: "BwModelica-Light";
    font-size: 35px;
    color: white;
    text-align: left;
}
.banner-btn{
    width: 165px;
    /* box-shadow: 3px 5px 3px #ededed;     */
}
.banner-img{
    width: 480px;
    margin-top: -80px;
}
/* Section Leaders */
#leaders{background-color: white;}
.content{
    padding: 80px 0px;
}
.title-orange{
    font-family: 'Dans';
    font-size: 65px;
    color: #fe3c00;
    text-align: center;
}
.client-img {text-align: center;}

/* Section Quote  */
#quote{background-color: #f3f6f9;}
.sub-title{
    font-family: 'BwModelica';
    font-size: 35px;
    text-align: center;
}
.ppl-img{
    margin: auto;
    display: block;
}
.lpauthor-name{
    font-size: 20px;
    color: #fe3c00;
    text-align: center;
    font-family: 'BwModelica-Bold';
    text-transform: uppercase;
}
.author-bio{
    font-size: 20px;
    color: #000;
    text-align: center;
    font-family: 'BwModelica-Bold';
}
/* player section */
#player{
    background-color: white;
}
.episode-head-title{
    font-size: 18px;
    font-family: 'BwModelica';

}
.episode-head-title2{
    padding-left: 15px;
    font-size: 18px;
    font-family: 'BwModelica';

}

.episode-section{padding-top: 25px;}
.feature-episode-section{padding-top: 25px}

.fepisode-list{
    height: 140px;
}
.lpepisode-list{
    height: 70px;
}
.lpepisode-content{padding: 15px 0px; text-align: left;}
.lpepisode-description{
    text-align: left;
    font-family: 'BwModelica-Light';
    color: black;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.lpepisode-name{
    font-family: 'BwModelica-Bold';
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.more-link{
    cursor: pointer;
    color: #fe3c00; 
    font-family: 'BwModelica-Bold';
    font-size: 12px;
}
.more-link:after{
    cursor: pointer;
    color: #fe3c00; 
    font-family: 'BwModelica-Bold';
    font-size: 12px;
}
.lpepisode-img img{
    width: 120px;
    text-align: left;
}

/* All Episode */
tr{
    text-align: left;
}
.epi-img{
    width: 65px;
}
.epi-play-icon{
    width: 40px;
    vertical-align: middle;
}
.info-icon{
    width: 30px;
    vertical-align: middle;
}

/* hover effect */
.vid-clip-sm {
    width: 120px;
    height: 120px;
    position: relative;
    margin: 0 8px 8px 0;
    float: left;
  }
  
  .vid-clip-sm .vid-clip-play {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition-property: background-color;
    -moz-transition-property: background-color;
    -o-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
  }
  
  .vid-clip-sm .vid-clip-play {
    background-color: rgba(0, 0, 0, 0.3);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#66000000', endColorstr='#66000000'); /* IE */
  
  }
  
  .vid-clip-sm .vid-clip-play img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -22px 0 0 -22px;
    width: 54px;
    transition: transform .5s;
    cursor: pointer;
  }

  .vid-clip-sm .vid-clip-play img:hover {
    transform: scale(1.5);
  }

/* Section Reasons */
#reasons{background-color: #f3f6f9;}
.title-orange span{
    font-family: 'BwModelica-Bold';
    font-size: 38px;
    color: black;
    text-align: center;
}
.reason-header{
    text-align: center;
    margin-bottom: 40px;
}
.reason-border{width: 100%;}
.reason-head2{
    font-family: 'BwModelica-ExtraBold';
    font-size: 18px;
    color: black;
    margin-bottom: 15px;
}
.reason-no-point{
    background-color: #fe3c00;
    padding: 10px;
    border-radius: 50%;
    color: white;
    width: 50px;
    height: 50px;
    text-align: center;
    font-family: 'BwModelica-Bold';
    margin-top: -36px;
    margin-bottom: 30px;
}
.reason-description{
    text-align: left;
    font-family: 'BwModelica';
    color: black;
    font-size: 14px;
}
/* Host Section  */
#host{
    background-color: #fe3c00;
    padding-top: 80px;
}
.host-title{
    font-size: 18px;
    color: white;
}
.host-name{
    font-family: 'BwModelica-ExtraBold';
    color: white;
    font-size: 38px;
    padding-top: 10px;
}
.host-img{
    width: 450px;
}
.social-host{
    text-align: left; 
    padding-top: 10px;
}
.social-host img{
    margin-right: 15px;
    width: 20px;
}
.host-description{
    color: white;
    font-family: 'BwModelica';
    font-size: 16px;
    text-align: left;
    line-height: 30px;
}
/* About Section */
#about{
    padding: 80px 324px;
    line-height: 30px;
}
.lp-about-para{
    text-align: center;
    font-size: 16px;
    color: black;
}
.strong-txt{
    font-family: 'BwModelica-Bold';
}
/* Section Contact */
#contact{
    background: #f3f6f9;
}
.header-contact{
    font-family: 'BwModelica-ExtraBold';
    font-size: 38px;
    color: black;
    text-align: center;
}
.contact-form{
    width: 60%;
    margin: auto;
}
.contact-form input{
    border-radius: 35px;
    border: 2px solid #dcdedf;
    width: 100%;
    margin: 10px;
    padding: 12px 21px;
}
.contact-form button{
    background: #fe3c00;
    color: white;
    border: 1px solid #fe3c00;
    border-radius: 35px;
    padding: 6px 20px;
    margin: auto;
    display: block;
    margin-top: 10px;
}
/* Footer Section */
#footer{
    background: white;
}
.footer-dark-logo{
    width: 180px;
    display: block;
    margin: auto;
}

/*  Dropdown css */
.info-icon:after {
  cursor: pointer;
  color: #FCC;
  content: '\2807';
  font-size: 20px;
  padding: 0 5px;
}

.dropdownLanding {
   position: absolute;
    right: 20px;
    /* bottom:10px; */
    background-color: #F5F5F7;
    box-shadow: 0px 0px 3px 3px rgb(202 208 212);
    outline: none;
    opacity: 0;
    z-index: -1;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    border-radius: 10px;
    width: 400px;

}
.dropdownLanding >div {
    display: flex;
   padding: 7px;
    align-items: center;
}
.dropdownLanding >div >i {
   margin-right: 12px;
}

.dropdownLanding-container:focus {
  outline: none;
}

.dropdownLanding-container:focus .dropdownLanding {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
}
/*  feature dropdown */

.dropdownLandingFeature {
   position: absolute;
    right: 10px;
    /* top:0px; */
    background-color: #F5F5F7;
    box-shadow: 0px 0px 3px 3px rgb(202 208 212);
    outline: none;
    opacity: 0;
    z-index: -1;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    border-radius: 10px;
    /* width: 400px; */

}
.dropdownLandingFeature >div {
    display: flex;
   padding: 7px;
    align-items: center;
}
.dropdownLandingFeature >div >i {
   margin-right: 12px;
}

.dropdownLanding-container-feature:focus {
  outline: none;
}

.dropdownLanding-container-feature:focus .dropdownLandingFeature {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
}