.liveShwSubtitle{
    overflow: hidden;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    text-align: left;        
    max-width: 175px;
}
.ppbutton4{color: #ec116e;}
.liveBtn{    background-color: #eb1163;
    color: white;
    border-radius: 35px;
    font-family: 'Mukta' !important;
    padding: 8px 10px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px !important;
    border: none;
}
/* Mobile CSS */
@media only screen and (max-width: 768px){
    .show-img{height: 150px; width: 100%;}
    .emoji-sec img{ margin-left: 3px; } 
    .desktop-only{display: none;}                                       
    .brand-wrap a{display: none;}
    .login-content{padding: 10px 15px; text-align: center;}
    .live-bar{    position: absolute;
      top: 50%;
      left: 6%;
      color: white;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 600;}
  .live-show-title{font-size: 12px;}
  .card-body{padding: 0;}
  .m-tspace{padding-top: 15px;}
  .send-btn{
      background: none;
      border: none;
      float: right;
  }
  .send-btn img{ 
      width: 40px;
      position: absolute;
      right: 12px;
      bottom: 9px;
  }
  .brand-logo{width: 120px;}
  .liveShowImg {
    width: 100px;
border-radius: 15px 0 0 15px;
float: left;
margin-right: 12px;
}
.liveContent{
    /* padding: 30px 15px; */
    line-height: 16px;
}
.liveShwTitle{
    font-size: 14px;
    font-weight: 600;
    
}
.showGrid{
    box-shadow: 3px 5px 7px #ccc;
    border-radius: 15px;
    /* margin: 20px 15px; */
    padding: 0;
    display: flex
    
  }
  }
  
  
  /* COmmon CSS */
  .input-group-text:focus{
      outline: none;
  }
  .input-group-text{font-size: 12px; color: #ec116e; background-color: #FFF8FB; border: 1px solid #FFF8FB; border-radius: 35px; height: 40px; margin-top: 8px; border: 0px solid;}
  .form-control{border-radius: 35px; background-color: #FFF8FB; text-align: left; padding: 8px 0px; width: 100%; border: 0px solid; margin: 8px 0;}
  /* COmmon CSS */
  /* Desktop CSS */
  @media only screen and (min-width: 768px) {
   
    /* New UI */
        .emoji-sec img{ margin: 5px; cursor: pointer;}
        .emoji-sec i{ cursor: pointer;}
    /* New UI */
    .liveBroImg{
        min-width: 120px;    
        width:200px  
          }
    .BroGrid{
        box-shadow: 3px 5px 7px #ccc;
        border-radius: 15px;
        /* margin: 20px 15px; */
        padding: 0;
     text-align: center;
    }
      .showGrid{
          box-shadow: 3px 5px 7px #ccc;
          border-radius: 15px;
          /* margin: 20px 15px; */
          padding: 0;
          display: flex;
          max-height: 205px;
        }
        .liveShwTitle{
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 15px;
        }
       
    .liveShowImg {
    border-radius: 15px 0 0 15px;
    float: left;
    margin-right:12px;
    }
      .send-btn img{ 
          width: 40px;
          position: absolute;
          right: 12px;
          bottom: 9px;
      }
      .send-btn{
          background: none;
          border: none;
          float: right;
      }
      .text-box{
          width: 390px;
          height: 230px;
          overflow-y:scroll;
          overflow-x: hidden;
      }
      .login-content{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 450px;
          text-align: center;
      
      }
      .show-name{font-size: 16px; font-weight: 600;}
      .show-card{width: fit-content;
          /* margin: 40px,0,0,40px; */
          margin-left: 40px;
          margin-right: 40px;
      }
      .live-icon{width: 15px;}
      .live-bar{position: absolute; bottom: 35%; color: white; text-transform: uppercase; font-weight: 600; left: 10px;}
      .live-show-title{font-size: 12px;}
      /* .live-shw-icons i{padding: 0 2px;} */
  
  
      .card-body{background-color: #FFF8FB;padding: 10px; border-radius: 0 0 20px 20px; box-shadow: 3px 7px 5px #ccc;}
      .card-img-top{ background-position: center center;
          background-repeat: no-repeat; width: 300px; height: 220px; border-radius: 20px 20px 0 0;}
     .brand-logo{width: 180px; display: block; padding-bottom: 10px;}
     .brand-wrap{font-size: 12px; font-weight: 600; }
    
     .brand-wrap a{margin: 15px; color: #617d89; text-decoration: none;}
     .brand-wrap a:active, .brand-wrap a:hover, .brand-wrap a:focus{color: #ec116e;}
     .brand-wrap i{padding-right: 5px; font-size: 14px;}
      /* .logo{width: 180px;} */
     
     
      /* .login-txt{font-size: 13px; text-align: ;} */
      /* .login-input-txt{border-radius: 50%; background-color: lightpink; padding: 10px ;}
      .login-btn{border-radius: 50%; background-color: #ec116e; } */
  }
  input:focus{
      outline: none;
  }
  .live-icon{width: 15px;}
  .login-form{ width: 100%;}
  .login-input-txt{border-radius: 35px; background-color: #FFF8FB; text-align: center; padding: 8px 0px; width: 100%; border: 0px solid; margin: 8px 0;}
  .login-btn{border-radius: 35px; background-color: #ec116e; border: 0; color: white; font-size: 12px; font-weight: 500; width: 100%; padding: 12px;}
  .goliveBtn{background-color: #ec116e; border-radius: 35px; border: white; color: white;padding: 8px 12px; text-transform: uppercase; font-size: 12px; font-weight: 600;
    margin-right: 12px}
  .logo{width: 140px; padding-bottom: 20px;}
  .pause-btn{width: 30px; float: right;}
  .live-msg-input{border-radius: 35px; background-color: #fff; border: 1px solid black; text-align: left; padding: 8px 15px; width: 90vw; margin-bottom: 8px;}
  .live-msg-sec{position: fixed; bottom: 0;margin-top: 12px;

}