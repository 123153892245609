@font-face {
  font-family: "BwModelica-Black";
  src: local('BwModelica'), url("fonts/BwModelica/BwModelica-Black.woff") format('woff');
}

@font-face {
  font-family: "BwModelica-Bold";
  src: local('BwModelica'), url("fonts/BwModelica/BwModelica-Bold.woff") format('woff');
}

@font-face {
  font-family: "BwModelica-ExtraBold";
  src: local('BwModelica'), url("fonts/BwModelica/BwModelica-ExtraBold.woff") format('woff');
}

@font-face {
  font-family: "BwModelica-Hairline";
  src: local('BwModelica'), url("fonts/BwModelica/BwModelica-Hairline.woff") format('woff');
}

@font-face {
  font-family: "BwModelica-Light";
  src: local('BwModelica'), url("fonts/BwModelica/BwModelica-Light.woff") format('woff');
}

@font-face {
  font-family: "BwModelica-Medium";
  src: local('BwModelica'), url("fonts/BwModelica/BwModelica-Medium.woff") format('woff');
}

@font-face {
  font-family: "BwModelica";
  src: local('BwModelica'), url("fonts/BwModelica/BwModelica-Regular.woff") format('woff');
}

@font-face {
  font-family: "Dans";
  src: local('Dans'), url("fonts/dans_hand_writing/DansHandWriting.ttf") format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Poppins:wght@600&display=swap');