
/* Mobile only */
@media (min-width: 768px){
    .mobile-only{ display: none}
    .mobile-only-imp{display: none !important;}
    .page-catCha{padding: 0px 20px}
    .post-img{ 
        width: 100%;
         border-radius: 10px 10px 0 0;
        }
    .post-name{
        font-size: 28px;
        padding: 12px 6px;
        color: black;
    }
    .author-name{
        font-size: 12px;
        text-align: left;
        float: left;     
    }
    .pubdate{
        font-size: 12px;
        text-align: right;
    }
    .read-category-name{
        font-size: 11px;
        position: absolute;
        background-color: #222222;
        opacity: 0.8;
        padding: 3px 3px 0px 3px;
        margin: 6px;
        border-radius: 3px;
    }
    .read-category-name p{
        color: white;
    }
    .read-page-title{
        font-family: 'BwModelica-Bold';
        font-style: normal;
        font-size: 2em;
        line-height: 22px;
        padding: 31px 15px;
    }
    .post-mid-band{
        background-color: rgba(96, 25, 138, 0.9);
    }
    .post-mid-band p{
        color: #fff;
        opacity: 1;
        padding: 8px;
    }
    .post-card{
        border-radius: 10px !important;
        border: 1px solid #ebebeb !important;
        box-shadow: 3px 7px 5px #ebebeb !important;
    }
}
/* Desktop only */
@media (max-width: 767px){
    .desktop-only{ display: none}
    .post-img{ width: 100%;}
    .post-name{
        font-size: 16px;
    }
    .author-name{
        font-size: 12px;
        text-align: left;
    }
    .category-name{
        font-size: 12px;
    }
}
