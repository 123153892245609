.list{
    margin-left: 7rem;
    
}

.onactive:hover{
    background-color: rgb(243, 208, 214);
}
.onactive:active{
    background-color: rgb(240, 53, 84);
}
.onactive:focus{
    background-color: rgb(248, 95, 120);
    color: white;
}
.onactive{
    margin: 2px;
    box-sizing: border-box;
}