body * {
    margin: 0;
    font-family: 'BwModelica';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.contact-section a {color: #eb1163 !important; }
.likeCountNo{
    text-align: center;
    padding-bottom: 12px;
}
.contact-section{padding: 40px 0;}
.contact-text a{color: #eb1163;}
.contact-text p{ font-size: 16px; line-height: 28px;}
.contact-title{font-size: 34px; font-weight: 900; padding-bottom: 30px;}
.likeCountImg{
  width: 20px;
    padding: 5px 0;
    margin: auto;
    display: block;
}
.likeCounts{
    position: absolute;
    bottom: 2vh;
    right: 4vw;
    
}
.subscribedImg{
    width: 15px;
   
}
.isSubscribed{
    cursor: pointer;
}
.isNotSubscribed{
    /* width: 40px; */
}
.subscribeImg{
    width: 60px;
    margin-right: -5px;
    margin-top: -4px;    
}
.screenbg{
    background-color:#fde6ef;
    padding: 60px 0px;
}
.mobiscreen{width:300px; display: block; margin: auto;}
code {
    font-family: 'Mukta', sans-serif;
}
/* App lnguage popup */
.profile-body{ margin-top: 20px;}
.lang-title{
    padding: 15px 20px;
    font-size: 21px;   
}
.lang-pink-btn button{
    background-color: #eb1163;
    border-radius: 35px;
    color: white;
}
/* App lnguage popup */
.lang-btn button {
    border-radius: 45px;
    border: 1px solid #eb1163;
    background: white;
    color: #eb1163;
    box-shadow: 0 0 black;
}
.lang-btn-filter button{
    border-radius: 45px;
    border: 1px solid white;
    background-color: white;
    padding: 4px 20px;
    box-shadow: 3px 5px 7px #ccc;
}
.lang-card .confirm-btn{
    border-radius: 45px;
    border: 1px solid #eb1163;
    background: #eb1163;
    color: white;
    box-shadow: 0 0 black;
    border-radius:35px !important;
}

/* .lang-btn button.active, .lang-btn button:hover, .lang-btn button:focus{
    background: #eb1163 !important;
    color: white !important;
    border: 1px solid #eb1163;
} */
/* .lang-btn button.active,.lang-btn button:hover .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    background: #eb1163 !important;
    color: white !important;
} */
.btn-link{
    border: 0 !important;
}
.lang-card{
    margin: auto;
    padding: 10px;
    border-radius: 8px;
}

.lang-sec{
    margin-top: -32px;
    margin-bottom: 8px;
}
.lang-btn-input label {
    border-radius: 45px !important;
    border: 1px solid #888;
    background: white !important;
    color: #888;
    box-shadow: 0 0 black;
}
.lang-btn-input label:hover, .lang-btn-input label:active{
    border-radius: 45px !important;
    border: 1px solid #888;
    background: #eb1163 !important;
    color: #fff;
    box-shadow: 0 0 black;
}
button.confirm-btn{
    background-color: #eb1163 !important;
    color: #fff;
    border: 0;
    box-shadow: 0 0 black;
}
/* button.btn.btn-primary:hover, button.btn.btn-primary:active{
    background-color: #eb1163;
    color: #fff;
} */
/* .confirm-btn{
    background-color: #eb1163;
    color: #fff;
} */
/* .lang-btn.btn.btn-primary{
    background-color: #eb1163;
    color: #888;
} */
/* label.btn-primary:hover,label.btn-primary:active {
    border-radius: 45px !important;
    border: 1px solid #888;
    background: #eb1163 !important;
    color: #fff;
    box-shadow: 0 0 black;
} */
/* Login CSS */
.loginHeadline{
    font-size: 24px;
    font-weight: 700;
}

.loginPage{
    background-color: #fde6ef;
    padding: 10px 30px;
    /* margin-bottom: 100px; */
}
.loginScreen{
    text-align: left;
    line-height: 2.0em;

}
.loginScreen i {
    padding-right: 10px;
}
.loginScreen .fa-comment-alt{
    color: #104284;
}
.loginScreen .fa-bookmark{
    color: #ff8f20;
}
.loginScreen .fa-heart{
    color: #eb1163;
}
.login-btn{
    border-radius: 35px;
    padding: 10px 30px !important;
    background-color: #eb1163;
    border: #eb1163; 
    color: #fff;
    margin: auto;
    display: block;
}

.loginBtn{
    border-radius: 35px;
    padding: 10px 30px;
    background-color: #eb1163;
    border: #eb1163;
}
.loginBtn a{
    color: white !important;
}
.loginvectorImg{
    text-align: center;
}
.loginvectorImg img {
    width: 180px;
    padding-top: 40px;
}
.loginvectorImg i{
    padding: 0 10px;
}
.loginvectorImg .fa-comment-alt{
    color: #104284;
}
.loginContent{
    text-align: center;
    padding: 20px 50px;
}
.MyShowBtn{
    margin: 30px 0;
}
.LoginMyShowCont{
    text-align: center;
}
/* Header CSS */
.logo{
    width: 80px;
}
.navbar{
    background-color: white;
    margin: 0;
    padding: 0 0 0 10px !important;
}
/* Show CSS*/
.showDetailsHeader{
    padding-bottom: 100px;
    box-shadow: 3px 4px 5px #efeeee;
    margin-bottom: 8px;
}
.showDetails-content{
    border-top: 1px  solid #efeeee;
}
.showBtn {
    padding-top: 10px;
    /* padding-bottom: 8px; */
    margin-top: 10px;
}

.showBtn small {
    display: block;
    text-align: left;
}
.showBtn .col, i {
    text-align: center;
    font-size: 12px;
    font-weight: 900;
}
.categoryName{
    color: #016135;
    padding-right: 20px;
}
.varText{
    font-size: 14px;
    font-weight: 400;
}
.varText1{
    font-size: 12px;
    font-weight: 400;
    color: #9c9c9c;
}
.nav-tabs .nav-item{
    width: 50%;
}
.sticky-navbar{
   position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    color: #eb1163 !important;
} 
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
    color: #888888 !important;
}
.showDetails{
    padding-top: 10px;
}
.showDetails a {
    color: #eb1163;
}
.showDetails span{
    float: left;
    /* margin-left: 5px; */
}
.btn{
    margin: 0 !important;
    padding: 8px 20px !important;
    border-radius: 8px !important;
}
.btn i{padding-right: 5px; color: #ffffff !important}

.showDescription{
    margin-top: -30px;
}

.artistName{
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    padding-top: 20px;
    margin-bottom: 0;
}

span.episodeName{
    font-weight: 900;
}

.episodeDesc span{
    font-size: 14px;
    color: #888888 ;
}
.episodeDesc div{
    color: #888888;
}

.episodeDesc a{
    color: #eb1163 ;
}
.episodeDuration div span{
    font-size: 14px;
    color: #888888;
}

div.playBtn.col{
    text-align: center;
}

.tab-content{
    padding-top: 10px;
}

div.showImg.card{
    border-radius: 10px;
}
.showDetailName{
    font-size: 22px;
    /* font-weight: 600; */
    margin-bottom: 6px;
}
.input-group> .form-comment-input{
    width: 95% !important;
    height: 42px;
    border-radius: 35px !important;
}
.comment-btn{
    position: absolute;
    right: 15px;
    top: 1px;
    z-index: 5;
}
.player-form-comment-input{
    width: 100% !important;
    height: 46px;
    border-radius: 35px !important;
}
.player-comment-btn{
    position: absolute;
    right: 15px;
    width: 46px;
    top: 0px;
    z-index: 5;
}
.commentName{
    font-size: 12px;
    padding-right: 10px;
    text-transform: capitalize;
}
.commentDuration{
    font-size: 12px;
    color: #cccccc;
}
.commentDesc{
    font-size: 14px;
    font-weight: 500;
    display: block;
}
.comment-img{
    margin: 8px;
}
.commentUserPic{
    float: left;
    width: 60px;
}
.comment-description{
    margin: 8px 0;
}
/* .commentBody{
    
} */
/* Channel CSS */

.headerBackground{
    margin-top: 20px;
    background: #302b29;
    /* height: 100%; */
    width: 100%;
}
.ChannelBody{
    border-radius: 25px 25px 0 0;
    background: white;
    padding: 0 20px;
    margin-top: 0px;
}
.channelName{
    font-size: 25px;
    font-weight: 400;
    padding-top: 36px;
}
.channelPara{
    text-align: left;
}
.ChannelGrid{
    padding: 20px 0;
}
.tagName{
    font-size: 10px;
    color: #ed2872;
    font-weight: 600;
    margin-left: 20px;
}


.shareText{
    font-size: 13px;
}
.channelLogo {
    margin: auto;
}

.ShowImage{
    width: 100px;
    /* border-radius: 10px; */
}



.bottomPadding10{
    padding-bottom: 16px;
}

/* Category CSS */

.categoryBackground{
    background: rgb(243 212 243);
    height: 100%;
    width: 100%;
}

.CatBody{
    border-radius: 25px 25px 0 0;
    background: white;
    padding: 20px;
    /* box-shadow: 0px -9px 10px -5px grey;  */
}



.catImage{
    height: auto;
    width: 80px;
    border-radius: 10px;
    /* box-shadow: 0px 0px 10px 2px grey;  */
}

.ShowImage{
    width: 100px;
    border-radius: 10px;
    /* box-shadow: 0px 0px 10px 2px grey;  */
    margin-bottom: 8px;
}

.categoryGrid{
    padding: 20px 0;
}



/* .categoryLogo img{
    width: 40px;
} */


.topbar{
    padding: 10px 20px 10px 20px;
}

.ShowImage{
    width: 100px;
}
/* Full Player */

.playerPic{
    width: 150px;
    margin: auto;
    border-radius: 15px;
    /* padding-top: 35px; */
    display: block;
}
.episodeThumb{
    margin: auto;
}
.playerText{
    text-align: center;
    font-size: 21px;
    font-weight: 900;
    padding-top: 20px;

}
.socialIcon{
    width: 30px;
    padding: 3px;
    border: 1px solid #cccccc;
    border-radius: 40px;
    margin: auto;
    display: block;

}
.social-buttons{
    display: block;
    margin: auto;
    padding-bottom: 20px;
}
.playerBtn span {
    font-size: 12px;
    color: #b2b2b2;
    text-align: center;
    padding-bottom: 10px;
}
.plyrAdditionalBtns{
    font-size: 500px;
    
}
.playr-actions-nav .col{
    text-align: center;
}
.playr-actions-nav p{
    font-size: x-small;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
}
/* Player CSS*/
/* .rhap_time{display: none;} */
.episodePic{
    /* width: 100px; */
    border-radius: 15px 0 0 15px;
}
.playerSec{
    position: fixed;
    display: block;
    bottom: 0px;
    left: 0px;

}
.rhap_play-pause-button{
    width: 50px;
    height: 50px;
}
/* .playerAudio{
    height: 60px;
    background-color: #1d1d1d !important;
    color: #ffffff !important;
    border-radius: 0 15px 15px 0;
} */
.rhap_progress-bar{
    height: 2px !important;
}
.rhap_progress-filled{
    background-color: #eb1163 !important;
}
.rhap_download-progress{
    background-color: #841740 !important;
}
.rhap_progress-bar-show-download{
    background-color: #841740 !important;
}
.rhap_progress-container{
    height: 2px !important;
}
.rhap_progress-indicator{
    width: 10px !important;
    height: 10px !important;
    top: -4px !important;
    background: #eb1163 !important;
    border: 3px solid #fff;
}
/* div.rhap_time{
    color: white;
} */
/* .rhap_time, .rhap_rewind-button, .rhap_forward-button{display: none;} */

/* My Shows Css */
.myShowsTab .nav-link{padding: 12px 12px !important;}
.nav-tabs .nav-link{
    border: 1px solid transparent !important;
    /* padding: .5rem 1rem; */
}
.nav-tabs{
    border: 1px solid transparent !important;
}
.nav-tabs .nav-link.active{
    border-bottom: 3px solid #eb1163  !important;
}
.testbg{
    background: yellow;
    width: 100%;
}
.searchForm{
    width: 100%;
}
.searchSubs input{
    border-radius: 12px;
    width: 340px;
}
.recentShows{
    padding-top: 20px;
    font-size: 12px;
    font-weight: 500;
}
.recentShows a{
    color: #eb1163;
}
.recentShows span{
    color: #eb1163; float: right; padding: 0 20px;
}
.subTitle{
    font-size: 9px;
    color: #b2b2b2;
}
div.showCardSub.card{
    border-radius: 10px;
    width: 100%;
}

.breadcrumbs{ padding-top: 20px; font-size: 12px; padding: 0 20px; font-weight: 500;}

.breadcrumbs span { color: #eb1163; float: right; padding: 0 20px;}
.tab-content{width: 100%;}
.episodeList{padding: 10px 0;}
.showDetailImg{ width: 240px !important; }

/* Header Tab CSS */
.navTabBody .showImgCard{
    border-radius: 50%;
    padding: 5px;
    margin-bottom: 8px;
    box-shadow: none;
}
.navTabBody .showImgCard .card-img {
    border-radius: 50%;
    padding: 0;
}