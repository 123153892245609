.asterisk{
  color: red;
}
  .card-footer a{
    color: #ec1165;
  }
  .card-title{
    color: #000000c7;
  }
  .card-text{
    color: #000000a3;
  }
  .card a {
    text-decoration: none !important;
  }
  .card:hover {
    box-shadow: 0px 0px 19px 8px rgba(33,33,33,.2);
  }
  
  
  #exTab2 {
    /* color : white;
    background-color: #428bca; */
    border: 1px solid #8080807a;
    padding : 5px 15px;
  }
  .tab-box{
    border-bottom: 1px solid #8080807a;
  }
  .tab-box a {
    text-decoration: none;
  }
  .card-header {
    text-align: left;
  }
  .card-header button{
    text-decoration: none !important;
  }
  .panel-title {
    font-size: 20px;
    font-weight: 700;
    color: #606060;
  }
  
    
  /* .panel-title::after {
      content: "\f107";
      color: #333;
      top: -2px;
      right: 0px;
      position: absolute;
      font-family: "FontAwesome"
  } */
  
  /* .panel-title[aria-expanded="true"]::after {
      content: "\f106";
  } */
  .card-body{
  
    text-align: left;
  
  }
  .online-card{
    padding: 10px;
  }
  
  #myImg {
    border-radius: 5px;
    cursor: pointer !important;
    transition: 0.3s;
  }
  #myModal7{
    opacity: 1 !important;
  }
  .showimage{
    max-width: 100%;
      height: auto;
      vertical-align: middle;
  }
  .modal{
    top: 50px !important;
  }
  .media-content #myImg{
    width: 100% !important;
  }
  .panel-heading{
    text-align: left !important;
  }
  .mediadivide{
    border-right: 1px solid #00000087 !important;
  }
  .textLeft
  {
    text-align: left !important;
    font-family: sans-serif !important;
    /* font-size: 16px !important; */
    /* padding: 35px !important; */
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .textLeft a
  {
    color: #ed4b64 !important;
  }
  @media only screen and (max-width: 800px) {
  /* iframe{
    width: 100% !important;
    height: 615px !important;
  } */
  }
  
  .faqtext .panel-heading{
    padding: 15px !important;
    color: #ed4b64 !important;
  
  }
  .faqtext p {
    text-align: left !important;
  }
  .faqtext{
    padding-top: 25px !important;
  }
  .faqtext p a{
    color: #ed4b64 !important;
  }
  .contactForm{
    background: #d3d3d333 !important;
  }
  nav{
    border-bottom: 1px solid lightgrey !important;
    background: #fff !important;
  }