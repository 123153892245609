.activeheader{
    color:  #eb1163 !important;
}
.activeheader i{
    color:  #eb1163 !important;
}

.headerHover:hover{
    color:  #eb1163 !important;
}
.dropdownHeaderMore {
    position: absolute;
    background-color: #F5F5F7;
    box-shadow: 0px 0px 3px 3px rgb(202 208 212);
    outline: none;
    opacity: 0;
    z-index: -42;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    border-radius: 10px;
    width: 30%;
    margin: 0px auto;
    max-width: 100px;
     display: none;
}
.dropdownHeaderMore >a {
   text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:12%;
}
.dropdownHeaderMore:hover  {
    display: block;
}

.dropdownHeaderMore-container:focus {
  outline: none;
  display: block;
}

.dropdownHeaderMore-container:focus .dropdownHeaderMore {
  opacity: 1;
  display: block;
  /* z-index: -1; */
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
}

/* PROFILR LOGOUT DROPDOWN*/
.Profile-icon:after {
  cursor: pointer;
  color: #FCC;
  content: '\2807';
  font-size: 20px;
  padding: 0 5px;
}
.dropdownHeaderProfile {
    position: absolute;
    left: 15px;
    background-color: #F5F5F7;
    box-shadow: 0px 0px 3px 3px rgb(202 208 212);
    outline: none;
    opacity: 0;
    z-index: -42;
    max-height: 0;
    transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    border-radius: 10px;
    width: 95%;
    margin: 0px auto;
    max-width: 100px;
    display: none;
}
.dropdownHeaderProfile >div {
   text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:12%
}

.dropdownHeaderProfile-container:focus {
  outline: none;
}

.dropdownHeaderProfile-container:focus .dropdownHeaderProfile {
  opacity: 1;
  display: block;
  /* z-index: -1; */
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
}